'use client'
import { useLoaderData, useSearchParams } from '@remix-run/react'
import { useState } from 'react'
import { calculateOrderSubtotal, calculateOrderTotal } from '#app/utils/order.ts'

import CartItem from './ui/cart-item'

export const CartIcon = (props) => {
	const height = props.height || 30
	const width = props.width || 30
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height={height}
			width={width}
			{...props}
		>
			<path fill="none" d="M0 0h24v24H0z" />
			<path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 01.958 1.287l-2.4 8a1 1 0 01-.958.713H6v2h11v2H5a1 1 0 01-1-1V6.414zM5.5 23a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm12 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
		</svg>
	)
}

export function Cart({ cart }) {
	const [searchParams, setSearchParams] = useSearchParams()
	const cartOpen = searchParams.get('cartOpen') === 'true'

	if (!cart) {
		return null
	}

	const subtotal = (calculateOrderSubtotal(cart.items) / 100).toFixed(2)


	return (
		<>
			<div className="drawer drawer-end z-10">
				<input
					id="cart-drawer"
					defaultChecked={cartOpen}
					type="checkbox"
					className="drawer-toggle"
				/>
				<div className="drawer-side">
					<label htmlFor="cart-drawer" className="drawer-overlay"></label>
					<div className="flex gap-4 min-h-screen w-80 flex-col bg-base-200 p-4 text-base-content">
						<p className="py-4 text-xl font-bold">Your Cart</p>
						{cart.items?.map((item, idx) => (
							<CartItem key={item.id} item={item} idx={idx} />
						))}
						<p className="py-4 text-xl font-bold">Subtotal: ${subtotal}</p>
						<a href="/order">
							<button className="btn btn-secondary">Go to Checkout</button>
						</a>
					</div>
				</div>
			</div>
		</>
	)
}
